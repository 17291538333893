import { FC, useState, MouseEvent } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Typography } from '@mui/material';

const DROPDOWN_WIDTH = 300;

const StyledMenu = styled(Menu)(({ theme, labelPresent }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    maxWidth: labelPresent ? DROPDOWN_WIDTH : 'none',
    width: 'fit-content',
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingRight: '45px',
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));


const StyledButton = styled(Button)(({theme, variant}) => ({
  maxWidth: DROPDOWN_WIDTH,
  width: '100%',
  ...(variant === 'plain' ? {
    padding: '15px 0',
  } : {
    border: '1px solid #8A8A8A',
    padding: '15px 20px',
  }),

  '&:hover': {
    background: 'none'
  }
}));

const ButtonInner = styled("div")(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
}));

type CustomDropdownProps = {
  options: string[];
  selectedValue: string;
  setSelectedValue(v: string): void;
  variant?: 'plain' | 'outlined';
  customLabel?: string;
  labelPresent?: boolean;
}

export const CustomDropdown: FC<CustomDropdownProps> = ({ options, selectedValue, setSelectedValue, customLabel, variant = 'outlined', labelPresent = true }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (option: string) => {
    setSelectedValue(option)
    handleClose()
  }

  return (
    <div>
      <StyledButton
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant={variant}
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        <ButtonInner>
          {labelPresent && <Typography variant="body1" color={variant === 'plain' ? '#6F6F6F' : "black"} fontWeight={variant === 'plain' ? 400 : 600}>{customLabel || "Оберіть розмір"}</Typography>}
          <Typography variant="body1" color="black">{selectedValue}</Typography>
        </ButtonInner>
      </StyledButton>
      <StyledMenu
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        labelPresent={labelPresent}
      >
        {options?.map((option =>
          <MenuItem onClick={() => handleSelect(option)} key={option} disableRipple>
            {option}
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  );
}