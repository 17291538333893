import { FC } from 'react';
import { styled } from '@mui/material/styles';
import { ReactComponent as LogoSvg } from '../assets/logo.svg';

const MainScreen = styled('div')(() => ({
  width: '100vw',
  height: '100vh',
  position: 'fixed',
  overflow: 'hidden',
  zIndex: 10,
  background: '#fff',
  top: 0,
  left: 0,
  pointerEvents: 'none',

  '.logoLoader': {
    position: 'absolute',
    bottom: '50%',
    left: '50%',
    transform: 'translate(-50%, 0)',
    display: 'flex',
    alignItems: 'flex-end',

    '&.-to-fill': {
      height: '74px',
      overflow: 'hidden',
      animation: 'fillUp 3s ease-in-out infinite',
    }
  },
  
  '.-empty': {
    'path' : {
      fill: '#ccc'
    }
  },

  '.-full': {

    'path' : {
      fill: '#000'
    }
  },     
}));

const LogoWrapper = styled('div')(() => ({

}))

type LoaderProps = {
}

export const Loader: FC<LoaderProps> = () => {
  return (
    <>
      <MainScreen>
        <LogoSvg className="logoLoader -empty" />
        <LogoWrapper className="logoLoader -to-fill">
          <LogoSvg  className="-full" />
        </LogoWrapper>
      </MainScreen>
    </>
  )
}