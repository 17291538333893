import { createContext, useEffect, useState, FC, ReactNode, useMemo, useCallback, useContext } from 'react';
import { Product, ProductModification, getAll } from '../api/Product';
import { loadImageAsync } from '../utils';
import { useLocation } from 'react-router-dom';
import { PromotionContext } from './PromotionContext';

export type ProductFilter = {
  fieldName: string;
  values: string[];
  isSpecificationFilter: boolean;
}

type ProductContextValues = {
  loading: boolean;
  products: Product[];
  filteredProducts: Product[],
  filters: ProductFilter[];
  sortBy: string;
  searchString: string;
  setSearchString(searchString: string): void,
  setSortBy(sortBy: string): void;
  setFilters(filters: ProductFilter[]): void;
}

export const ProductContext = createContext<ProductContextValues>({
  loading: true,
  products: [],
  filteredProducts: [],
  sortBy: '',
  searchString: '',
  setSearchString: () => {},
  setSortBy: () => {},
  filters: [],
  setFilters: () => {}
});

export const baseFilters: ProductFilter[] = [
  {
    fieldName: 'category',
    values: [],
    isSpecificationFilter: false,
  },
  {
    fieldName: 'color',
    values: [],
    isSpecificationFilter: true,
  },
  {
    fieldName: 'size',
    values: [],
    isSpecificationFilter: true,
  },
]

export const sortingOption = ['рівнем популярності', 'зростанням ціни', 'спаданням ціни']

export const ProductContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [sortBy, setSortBy] = useState(sortingOption[0]);
  const [filters, setFilters] = useState<ProductFilter[]>(baseFilters);
  const [searchString, setSearchString] = useState('');
  const [lastFetcherAt, setLastFetchAt] = useState<number>(0)
  const location = useLocation()
  const { loading: loadingPromotions, relatedPromotion, isActive } = useContext(PromotionContext)

  const compareSizes = (pm: ProductModification, size: string): boolean =>
    pm.additional_fields['size']?.includes('-')
      ? (pm.additional_fields['size'].endsWith(`-${size}`) || pm.additional_fields['size'].startsWith(`${size}-`))
      : pm.additional_fields['size'] === size

  const filteredProducts = useMemo(() =>
    products.filter((product) =>
      (product.title?.toLowerCase()?.includes(searchString?.toLowerCase())
        || product.additional_fields['n_collection']?.toLowerCase()?.includes(searchString?.toLowerCase()))
        && filters.every((filter) =>
            !filter.values?.length ||
              filter.values.some((value) =>
                filter.isSpecificationFilter
                  ? !!product.product_modifications.find((pm) => filter.fieldName === 'size' ? compareSizes(pm, value) : pm.additional_fields[filter.fieldName] === value)
                  : product.additional_fields[filter.fieldName] === value
              )
          )
    ).sort((a,b) => {
      if (sortBy === 'рівнем популярності') {
        return a.additional_fields['popular_lvl'] > b.additional_fields['popular_lvl'] ? -1 : 1
      } else if (sortBy === 'зростанням ціни') {
        return parseFloat(a.product_modifications[0]?.price) > parseFloat(b.product_modifications[0]?.price) ? 1 : -1
      } else if (sortBy === 'спаданням ціни') {
        return parseFloat(a.product_modifications[0]?.price) > parseFloat(b.product_modifications[0]?.price) ? -1 : 1
      } else {
        return 0
      }
    })
  , [products, filters, sortBy, searchString])

  const loadImages = useCallback((images: { url: string }[], callback: () => void) => {
    if(!products?.length) return;

    let counter = 0

    images.map((imageToLoad) => {
      const img = new Image();
      img.src = imageToLoad["url"];
      img.onload = () => {
        counter = counter + 1

        if (counter === images.length) {
          callback()
        }
      }
    })
  }, [])

  const fetchData = async () => {
    setLoading(true)
    const allProducts: Product[] = await getAll()
    const productsWithPromotionData = (structuredClone(allProducts) as Product[]).map((pr) => {
      const promo = relatedPromotion(pr)
      if (promo && isActive(promo)) {
        pr.product_modifications.map((pm) => {
          const currentPrice = parseFloat(pm.price)
          const discount = parseFloat(promo.discount)
          pm.oldPrice = currentPrice.toString()
          pm.price = (promo?.discount_type === "Відсоткова" ? (currentPrice - currentPrice*discount/100) : (currentPrice - discount)).toString()

          return pm;
        })
      }

      return pr
    })
    setProducts(productsWithPromotionData)
    const imagesToLoad = productsWithPromotionData.map((el) => el.additional_fields["preview_images"]).flat()
    loadImages(imagesToLoad, () => setLoading(false))
    setTimeout(() => setLoading(false), 3000)
    setLastFetchAt(Date.now())
  }

  useEffect(() => {
    if (!loadingPromotions && Date.now() - lastFetcherAt > 1800000)
      fetchData()
  }, [location, loadingPromotions])

  return (
    <ProductContext.Provider
      value={{
        loading,
        products,
        filteredProducts,
        filters,
        sortBy,
        searchString,
        setSearchString,
        setSortBy,
        setFilters
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};
