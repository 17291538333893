import { FC, useContext, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Badge, IconButton, TextField } from '@mui/material';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { ReactComponent as LogoSmallSvg } from '../assets/logoSmall.svg';
import { ReactComponent as MenuSvg } from '../assets/menu.svg';
import { ReactComponent as CartSvg } from '../assets/cart.svg';
import { ReactComponent as SearchSvg } from '../assets/search.svg';
import { Menu } from '../components/Menu';
import Landing from './Landing';
import Catalogue from './Catalogue';
import Item from './Item';
import Cart from './Cart';
import { CartContext } from '../contexts/CartContext';
import CartConfirm from './CartConfirm';
import { ProductContext } from '../contexts/ProductContext';
import { Close } from '@mui/icons-material';
import { HEADER_HEIGHT, MOBILE_BREAKPOINT } from '../utils';
import { Loader } from '../components/Loader';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { RunningLine } from '../components/RunningLine';
import { PromotionContext } from '../contexts/PromotionContext';
import { CommonContext } from '../contexts/CommonContext';

const Container = styled('div')(({ theme }) => ({
  fontFamily: "Montserrat, Rubik, sans-serif",
  width: '100%',
  color: '#000000',
  paddingTop: HEADER_HEIGHT,
  maxHeight: '100vh'
}));

const HeaderInner = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 20px',
  width: "100%",
  maxWidth: "1700px",
  margin: '0 auto',
}));

const Header = styled('div')(({theme}) => ({
  width: '100%',
  height: HEADER_HEIGHT,
  display: 'flex',
  background: '#fff',
  position: 'fixed',
  zIndex: '100',
  left: '0',
  top: '0',
  borderBottom: '1px solid #DADADA',
}));

const Logo = styled('div')(() => ({
  position: 'absolute',
  left: '50%',
  transform: 'translate(-50%,0)',
  cursor: 'pointer'
}));

const LeftBlock = styled('div')(() => ({
}));

const RightBlock = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  color: '#414141',

  '&>span': {
    display: 'block',
    width: '1px',
    height: '21px',
    margin: '0 15px',
    background: '#414141'
  }
}));

const TitleLine = styled('div')(() => ({
  width: '100%',
  display: "flex",
  flexDirection: 'column',
  top: HEADER_HEIGHT,
  position: 'fixed',
  zIndex: '100',
  backgroundColor: "rgba(255,255,255,.5)",

}));

const TitleLineInner = styled('div')(() => ({
  display: 'flex',
  padding: '12px 20px 20px',
  justifyContent: "space-between",
  width: "100%",
  maxWidth: "1700px",
  margin: '0 auto',
  position: 'relative',
  zIndex: '100',

  '&:before': {
    zIndex: '100',
    content: "' '",
    display: 'block',
    background: '#414141',
    width: '228px',
    height: '1px',
    position: 'absolute',
    transform: 'translate(-50%,0)',
    left: '50%',
    top: '-1px'
  }
}));

export const Root: FC = () => {
  const navigate = useNavigate()
  const { items } = useContext(CartContext)
  const { title } = useContext(CommonContext)
  const [menuOpen, setMenuOpen] = useState(false)
  const [searchBarOpen, setSearchBarOpen] = useState(false)
  const {loading, searchString, setSearchString} = useContext(ProductContext)
  const { width } = useWindowDimensions()
  const { activeGlobalPromosion, loading: loadingPromotions } = useContext(PromotionContext)
  const isBlackFridayPromo = useMemo(() =>
    activeGlobalPromosion?.title?.includes('Black Friday')
  , [activeGlobalPromosion])

  return (
    <Container style={loading || loadingPromotions ? { maxHeight: '100hv', overflow: 'hidden', pointerEvents: 'none' } : {}}>
      <Header>
        <HeaderInner>
          <LeftBlock>
            <IconButton onClick={(): void => setMenuOpen(true)}>
              <MenuSvg />
            </IconButton>
            <Menu open={menuOpen} setOpen={setMenuOpen} />
          </LeftBlock>
          <Logo onClick={(): void => navigate('/')}>
            <LogoSmallSvg />
          </Logo>
          <RightBlock>
            {width > MOBILE_BREAKPOINT
              ? <>
                  {
                    (searchBarOpen || searchString)
                      ? <TextField
                          variant="standard"
                          autoComplete='false'
                          placeholder='почніть вводити..'
                          value={searchString}
                          onChange={(e: any) => setSearchString(e.target.value)}
                          fullWidth={false}
                        />
                      : null
                  }
                  <IconButton
                    onClick={(): void => {
                      if(searchBarOpen) {
                        setSearchBarOpen(false)
                        setSearchString('')
                      } else {
                        setSearchBarOpen(true)
                        navigate('/catalogue')
                      }
                    }}
                  >
                    {
                      searchBarOpen
                      ? <Close />
                      : <SearchSvg />
                    }
                  </IconButton>
                  <span />
                </>
              : null
            }
            <IconButton onClick={(): void => navigate('/cart')}>
              <Badge
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                badgeContent={items?.length}
                color="primary"
              >
                <CartSvg />
              </Badge>
            </IconButton>
          </RightBlock>
        </HeaderInner>
      </Header>
      <TitleLine>
        {isBlackFridayPromo && <RunningLine promotion={activeGlobalPromosion}/>}
        {title && <TitleLineInner>
          {title}
        </TitleLineInner>}
      </TitleLine>
      {
        (loading || loadingPromotions)
        && <Loader />
      }
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/catalogue" element={<Catalogue />} />
        <Route path="/catalogue/:id" element={<Item />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/cart/confirm" element={<CartConfirm />} />
      </Routes>
    </Container>
  );
};

export default Root;
