import { FC } from 'react';
import { Check } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  'svg': {
    marginRight: '5px'
  }
}));

type InStockBadgeProps = {
  value: boolean,
}

export const InStockBadge: FC<InStockBadgeProps> = ({ value }) => {
  return (
    <Container>
      {value ? <Check style={{ fontSize: '1rem', color: "#298542" }}/> : null}
      <Typography color={value ? "#298542" : "#9A9A9A"} fontWeight={value ? 600 : 400}>{value ? "є в наявності" : "передзамовлення"}</Typography>
    </Container>
  )
}