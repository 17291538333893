import { FC, useState } from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { Collapse, Divider, IconButton, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Typography } from '@mui/material';
import { CloseOutlined, ArrowDropDown, ArrowDropUp, StarBorder, Instagram } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const Container = styled(Paper)(({ theme }) => ({
  minWidth: "500px",
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  padding: '20px 40px 20px 75px',
  
  '@media (max-width: 767px)': {
    minWidth: "300px",
    padding: '20px 20px 20px 20px',
  },
}));

const CloseIconWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end"
}));

const MenuFooter = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: 'column',
  justifyContent: 'flex-end',
  flex: '1 1 auto'
}));

const MenuFooterItem = styled("div")(({ theme }) => ({
  display: "flex",
  margin: '5px 0',
  alignItems: 'center',
  '&>a': {
    display: 'flex',
    alignItems: 'center',

    'svg': {
      marginRight: '10px',
      color: '#6F6F6F'
    }
  }
}));

const ListItemTextBold = styled(ListItemText)(({ theme }) => ({
  span: {
    fontWeight: 600
  }
}));

type MenuProps = {
  open: boolean;
  setOpen(open: boolean): void;
}

export const Menu: FC<MenuProps> = ({ open, setOpen }) => {
  const navigate = useNavigate()

  return (
    <SwipeableDrawer
      anchor="left"
      open={open}
      onClose={(): void => setOpen(false)}
      onOpen={(): void => setOpen(true)}
    >
      <Container>
        <CloseIconWrapper>
          <IconButton onClick={(): void => setOpen(false)}>
            <CloseOutlined />
          </IconButton>
        </CloseIconWrapper>
        <List>
          <ListItemButton 
            component="a" 
            href="/catalogue" 
            onClick={(e) => { 
              e.preventDefault()
              setOpen(false)
              navigate('/catalogue')
            }}
          >
            <ListItemTextBold primary="каталог" />
          </ListItemButton>
        </List>
        {/* <Divider />
        <List>
          <ListItemButton>
            <ListItemText primary="про нас" />
          </ListItemButton>
          <ListItemButton>
            <ListItemText primary="повернення" />
          </ListItemButton>
          <ListItemButton>
            <ListItemText primary="доставка та оплата" />
          </ListItemButton>
        </List> */}
        <Divider />
        <MenuFooter>
          <MenuFooterItem>
            <Typography variant="body2">{''}</Typography>
          </MenuFooterItem>
          <MenuFooterItem>
            <Link href="https://instagram.com/nettie_ua" target="_blank">
              <Instagram />
              <Typography variant="body2">Instagram</Typography>
            </Link>
          </MenuFooterItem>
          <MenuFooterItem sx={{ opacity: .4 }}>
            © 2023 Nettie. Всі права захищені.
          </MenuFooterItem>
        </MenuFooter>
      </Container>
    </SwipeableDrawer>
  )
}