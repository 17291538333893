import { FC, ReactNode } from 'react';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { ukUA } from '@mui/material/locale';


export const ThemeProvider: FC<{ children: ReactNode}> = ({ children }) => {
  const CustomTheme = createTheme({
    palette: {
      primary: {
        light: '#414141',
        main: '#000000',
        contrastText: '#fff',
      },
      secondary: {
        light: '#414141',
        main: '#000000',
        contrastText: '#fff',
      },
    },
    typography: {
      h1: {
        color: '#383838',
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '26px',
        fontWeight: 600,
        lineHeight: '32px',
        letterSpacing: '0.02em',
        textAlign: 'left',
        textTransform: 'uppercase',
      },
      subtitle1: {
        color: '#383838',
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '18px',
        fontWeight: 400,
        lineHeight: '22px',
      },
      h2: {
        fontSize: '24px',
        fontFamily: "Montserrat, sans-serif",
        color: '#000000',
        fontWeight: 400
      },
      h3: {
        fontSize: '16px',
        fontFamily: "Montserrat, sans-serif",
        color: '#414141',
        fontWeight: 400
      },
      h4: {
        fontSize: '16px',
        fontFamily: "Montserrat, sans-serif",
        textTransform: 'uppercase',
        color: '#414141',
        fontWeight: 400
      },
      body1: {
        fontSize: '14px',
        fontFamily: "Montserrat, sans-serif",
        color: '#414141',
        fontWeight: 400
      },
      body2: {
        fontSize: '14px',
        fontFamily: "Montserrat, sans-serif",
        color: '#6F6F6F',
        fontWeight: 400,
      },
      subtitle2: {
        fontSize: '14px',
        fontFamily: "Montserrat, sans-serif",
        color: '#414141',
        fontWeight: 600
      },
    },
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            color: '#6F6F6F',
            borderBottom: 'none',
            padding: '5px',

            '&.MuiTableCell-root:first-child': {
              color: '#000',
            },

            '&.MuiTableCell-root.MuiTableCell-head': {
              fontWeight: 400
            },
          }
        }
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            '&:last-child .MuiTableCell-root': {
              borderBottom: 'none',
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: ({ ownerState }: { ownerState: any }) => ({
            textTransform: 'none',
            padding: "15px 20px",

            ...(ownerState.variant === 'contained' ? {
              fontSize: '14px',
              padding: "15px 20px",
              background: "#414141",
              color: '#fff',
              textAlign: 'left',
              justifyContent: 'space-between',
            } : {}),
            ...(ownerState.size === 'small' ? {
              fontWeight: '600',
              justifyContent: 'center',
              fontSize: '13px',
              padding: "4px 25px",
            } : {}),
          }),
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: ({ ownerState }: { ownerState: any }) => ({
            ...(ownerState.variant === 'standard' ? {
              "& input": {
                padding: "8px 0",
                height: 'auto'
              }
            } : {}),
          }),
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: ({ ownerState }: { ownerState: any }) => ({
            ...(ownerState.elevation === 2
              ? {
                boxShadow: "rgb(234, 236, 240) 0px 0px 1px, rgba(29, 41, 57, 0.08) 0px 1px 2px"
              }
              : {
                boxShadow: 'none'
              }
            ),

            '&.-inner': {
              background: 'rgb(249, 250, 251)'
            }
          }),
        },
      },
      MuiList: {
        styleOverrides: {
          root: ({ ownerState }: { ownerState: any }) => ({
            ...(ownerState.disablePadding ? {} : {
              padding: '10px 0 10px',
            }),
            color: "#6F6F6F"
          }),
        },
      },
      MuiListItemButton: {
        defaultProps: {
          disableTouchRipple: true,
        },
        styleOverrides: {
          root: ({ ownerState }: { ownerState: any }) => ({
            padding: '0 0 0 25px',
            
            '& span': {
              position: 'relative',
              display: 'inline'
            },

            '& span:after': {
              content: "' '",
              display: 'block',
              position: 'absolute',
              left: 0,
              bottom: 0,
              background: '#6F6F6F',
              height: '1px',
              width: '0px',
              borderRadius: '5px'
            },

            '&:hover, &:active': {
              background: 'rgba(255,255,255,0)',
              boxShadow: 'none',
            },

            '&:hover span:after': {
              width: 'calc(100% + 5px)',
              height: '1px',
              transition: "all .5s ease-in-out",
            }
          }),
        },
      }
    },
  },
  ukUA,
  );

  return (
    <MuiThemeProvider
      theme={CustomTheme}
    >
      {children}
    </MuiThemeProvider>
  );
};
