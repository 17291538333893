import { FC, useMemo } from 'react';
import { Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PRODUCT_COLOR, Product } from '../api/Product';
import { useNavigate } from 'react-router-dom';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { MOBILE_BREAKPOINT } from '../utils';
import { InStockBadge } from './InStockBadge';

const Container = styled(Paper)(({ theme }) => ({
  width: '100%',
  maxWidth: "380px",
  minWidth: "150px",
  display: "flex",
  flexDirection: "column",
  cursor: "pointer",
  position: "relative",
  textDecoration: 'none',
  textTransform: 'none',

  '&:after': {
    content: "' '",
    display: 'block',
    position: 'absolute',
    left: 0,
    bottom: 0,
    background: '#6F6F6F',
    height: '1px',
    width: '0px',
    borderRadius: '5px'
  },

  '&:hover:after': {
    width: '100%',
    height: '1px',
    transition: "all .5s ease-in-out",
  }
}));

const ProductImage = styled('div')(({ theme, backgroundimage, bgimgonhover, height }) => ({
  height,
  width: '100%',
  display: "flex",
  flexDirection: "column",
  backgroundImage: `url(${backgroundimage}), url(${bgimgonhover})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',

  ...(bgimgonhover 
    ? {
      '&:hover': {
        backgroundImage: `url(${bgimgonhover}), url(${backgroundimage})`,
      }
    } 
    : { }
  )
}));

const InfoBlock = styled('div')(({ theme }) => ({
  padding: '10px 0',
  width: '100%',
}));

const ColorBlock = styled('div')(({ theme }) => ({
  display: 'flex'
}));

const ColorCircle = styled('div')(({ theme, color }) => ({
  width: '12px',
  height: '12px',
  borderRadius: '50%',
  marginTop: '7px',
  boxShadow: '0 3px 7px rgba(0, 0, 0, 0.2)',
  backgroundColor: color,

  '& + &': {
    marginLeft: '15px',
  }
}));

const FirstLine = styled('div')(({ theme }) => ({
  width: '100%',
  display: "flex",
  flexWrap: 'wrap',
  justifyContent: 'flex-end',
}));

const PriceBlock = styled('div')(({ theme }) => ({
  display: "flex",
  justifyContent: 'flex-end',
}));

const SecondLine = styled('div')(({ theme }) => ({
  width: '100%',
  display: "flex",
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  alignItems: 'baseline'
}));

const BlackFridayBadge = styled('div')(({ theme }) => ({
  background: '#000',
  padding: '5px 25px',
  position: 'absolute',
  top: '20px',
  right: '-5px'
}));

type ProductItemProps = {
  product: Product,
  small?: boolean
}

export const ProductItem: FC<ProductItemProps> = ({ product, small = false }) => {
  const navigate = useNavigate()
  const fontSize = useMemo(() => small ? 'body1' : 'h4', [small])
  const { width } = useWindowDimensions()
  const hasDiscount = useMemo(() =>
    product.product_modifications.some((pm) => !!pm.oldPrice)
  , [product])

  if(!product)
    return <></>

  return (
    <Container component="a" href={`/catalogue/${product["id"]}`} onClick={(e: any) => { e.preventDefault(); navigate(`/catalogue/${product["id"]}`) }}>
      {hasDiscount && <BlackFridayBadge style={small ? { padding: '3px 10px' } : {}}><Typography color="#fff" fontWeight={600} fontSize={small ? '12px' : undefined}>{small ? 'sale' : 'Black Friday'}</Typography></BlackFridayBadge>}
      <ProductImage 
        backgroundimage={product.additional_fields["preview_images"] && product.additional_fields["preview_images"][0]["url"]} 
        bgimgonhover={(width > MOBILE_BREAKPOINT && product.additional_fields["preview_images"]?.length > 1) ? product.additional_fields["preview_images"][1]["url"] : ''} 
        height={small ? "262px" : "540px"}
        className="productImage"
      />
      <InfoBlock>
        <FirstLine style={small ? { paddingTop: 0 } : {}}>
          <Typography variant={fontSize} style={{ flex: '1 1 auto' }}>{[!small && product.additional_fields["n_collection"], product.title].filter((el) => !!el).join(', ')}</Typography>
          <PriceBlock>
            {
              hasDiscount &&
                <Typography variant={fontSize} color="#8A8A8A" fontWeight={small ? 600 : 400} style={{ textDecoration:"line-through", marginRight: '10px' }}>₴{product.product_modifications[0].oldPrice}</Typography>
            }
            <Typography variant={fontSize} color="black" fontWeight={small ? 600 : 400}>₴{product.product_modifications[0].price}</Typography>
          </PriceBlock>
        </FirstLine>
        {
          small
            ? null
            : <SecondLine>
                <ColorBlock>
                  {product.available_colors.map((color) =>
                    <ColorCircle color={PRODUCT_COLOR[color]} key={color} />
                  )}
                </ColorBlock>
                <InStockBadge value={product.product_modifications?.some((pm) => pm.in_stock)} />
              </SecondLine>
        }
      </InfoBlock>
    </Container>
  )
}