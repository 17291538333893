import { FC } from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { Promotion } from '../api/Promotion';

// Define the container with the desired styles
const MarqueeContainer = styled('div')(({ theme }) => ({
  backgroundColor: 'black',
  color: 'white',
  fontSize: '16px',
  padding: '5px 0',
  whiteSpace: 'nowrap',
  display: 'flex',
  overflow: 'hidden',

  '& div': {
    animation: 'marquee 50s linear infinite',
    display: 'flex'
  },

  '& p': {
    padding: '0 30px'
  }
}));

type RunningLineProps = {
  promotion?: Promotion
}

export const RunningLine: FC<RunningLineProps> = ({ promotion }) => {

  if(!promotion)
    return null;

  return (
    <MarqueeContainer>
      <div>
        {[...(new Array(50))].map((_, i) => 
          <Typography 
            key={Math.random()} 
            color="white" 
            fontWeight={600}
          >
            {
              i % 2 && promotion.expires_at 
                ? `до ${new Date(promotion.expires_at).toLocaleDateString('uk-UA', { day: '2-digit', month: '2-digit' })}` 
                : 'Black Friday'
            }
          </Typography>
        )}
      </div>
    </MarqueeContainer>
  );
};
