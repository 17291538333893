import { FC, useContext, useEffect, useMemo, useState } from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { Button, Collapse, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Typography } from '@mui/material';
import { CloseOutlined, ArrowDropDown, ArrowDropUp, StarBorder, Instagram } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { PRODUCT_COLOR, Product } from '../api/Product';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowRightSvg } from '../assets/arrowRightWhite.svg';
import { HEADER_HEIGHT } from '../utils';
import { CommonContext } from '../contexts/CommonContext';

const MainScreen = styled('div')(() => ({
  maxWidth: '100%',
  overflowX: 'hidden',
  paddingTop: '100px',
  maxHeight: `calc(100vh - ${HEADER_HEIGHT})`,
  paddingBottom: '100px',
}));

const Title = styled("div")(() => ({
  position: 'absolute',
  justifyContent: "space-arround",
  display: "flex",
  left: '50%',
  top: '0',
  paddingTop: '12px',
  transform: 'translate(-50%,0)',
}));

const Container = styled('div')(() => ({
  padding: '0 20px 20px',
  width: "100%",
  maxWidth: "1700px",
  margin: '0 auto',
}));

const SubmitLine = styled('div')(() => ({
  display: 'flex',
  padding: '0 20px 20px',
  width: "100%",
  maxWidth: "1700px",
  margin: '0 auto',
  justifyContent: 'flex-end',
  alignItems: 'flex-start'
}));

type MessagePageProps = {
  title: string,
  buttonText?: string
}

export const MessagePage: FC<React.PropsWithChildren & MessagePageProps> = ({ title, buttonText, children }) => {
  const navigate = useNavigate()
  const { setTitle } = useContext(CommonContext)

  useEffect(() => {
    setTitle(
      <Title>
        <Typography variant='h4' color="black">{title}</Typography>
      </Title>
    )

    return () => {
      setTitle(null)
    }
  }, [])

  return (
    <>
      <MainScreen>
        <Container>
          {children}
        </Container>
        <Divider />
        <br />
        <SubmitLine>
          <Button 
            variant='contained' 
            endIcon={<ArrowRightSvg style={{color: "white"}} />} 
            sx={{ minWidth: '300px' }}
            onClick={(): void => navigate('/catalogue')}
          >
            {buttonText || "Продовжити покупки"}
          </Button>
        </SubmitLine>
      </MainScreen>

    </>
  )
}