import { FC, useEffect, useState, useMemo, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { Alert, Button, Link, Radio, RadioGroup, Snackbar, Typography } from '@mui/material';
import { PRODUCT_COLOR } from '../api/Product';
import { ProductItem } from '../components/ProductItem';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CustomDropdown } from '../components/CustomDropdown';
import { Add, Check, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { ReactComponent as ArrowSvg } from '../assets/arrowRight.svg';
import { ProductContext } from '../contexts/ProductContext';
import { CartContext } from '../contexts/CartContext';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { useScrollToTop } from '../hooks/useScrollToTop';
import { InStockBadge } from '../components/InStockBadge';
import { HEADER_HEIGHT } from '../utils';
import { SizesDrawer } from '../components/SizesDrawer';

const MainScreen = styled('div')(() => ({
  maxWidth: "1300px",
  margin: '0 auto',
  paddingBottom: '100px',
  display: "flex",

  '@media (max-width: 767px)': {
    flexDirection: 'column'
  },
}));

const ImageBlock = styled('div')(() => ({
  width: "calc(50% - 100px)",
  display: 'flex',
  alignItems: 'flex-end',
  flexDirection: 'column',

  '@media (max-width: 767px)': {
    width: "100vw",
    flexDirection: 'row',
    overflowX: 'auto',
    height: "400px"
  },

  '&>img': {
    maxWidth: 'calc(50vw - 100px)',
    
    '@media (max-width: 767px)': {
      maxWidth: "none",
      height: "100%"
    },
  }
}));

const TextBlock = styled('div')(() => ({
  position: 'relative',
  padding: '0 20px 0 130px',
  flex: '1 1 auto',

  '@media (max-width: 767px)': {
    padding: '0 20px',
  },
}));

const TextBlockInner = styled('div')(() => ({
  position: 'sticky',
  top: '0',
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 auto'
}));

const Heading = styled('div')(() => ({
  marginTop: '100px',
  width: '100%',
  paddingBottom: '5px',
  position: 'relative',

  '@media (max-width: 767px)': {
    marginTop: '50px',
  },
  
  '&:after': {
    content: "' '",
    position: 'absolute',
    bottom: 0,
    display: "block",
    height: '1px',
    maxWidth: '514px',
    width: '90%',
    background: '#DADADA'
  }
}));

const BpIcon = styled('span')(({ theme, color }) => ({
  borderRadius: '50%',
  width: 20,
  height: 20,
  background: color,
  boxShadow: '0 3px 7px rgba(0, 0, 0, 0.2)',

  '.Mui-focusVisible &': {
    outline: 'none',
  },
  'input:hover ~ &': {
    background: color,
    opacity: .8
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: color,
  },
}));

const BpCheckedIcon = styled(BpIcon)(({ theme, color }) => ({
  backgroundColor: color,
  '&:before': {
    display: 'block',
    width: 20,
    height: 20,
    backgroundImage: `radial-gradient(${color === '#000' ? '#fff' : '#000'},${color === '#000' ? '#fff' : '#000'} 20%,transparent 32%)`,
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: color,
  },
}));

const StyledRadio = styled(Radio)(() => ({
  padding: 5
}));

const ToogleLabel = styled('div')(() => ({
  display: 'flex',
  cursor: 'pointer'
}));

const ToogleBlock = styled('div')(() => ({
  display: 'flex',
}));

const ButtonInner = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between'
}));

const SuggestedProducts = styled('div')(() => ({
}));

const InStockContainer = styled('div')(() => ({
  width: '300px',
  display: 'flex',
  justifyContent: 'flex-end',
}));

const Prices = styled('div')(() => ({
  display: 'flex',
}));

const SuggestedProductsControls = styled('div')(() => ({

  '&>svg + svg': {
    marginLeft: '10px'
  }
}));

const SuggestedProductsHeading = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline'
}));

const SuggestedProductsCards = styled('div')(() => ({
  display: 'grid',
  padding: '10px 0 0',
  width: "100%",
  maxWidth: "100%",
  gap: '30px',
  gridTemplateColumns: `repeat(auto-fill, minmax(min(150px, calc(100vh - ${HEADER_HEIGHT})), 1fr))`,
  justifyItems: 'center',

  '&>svg + svg': {
    gap: '10px'
  }
}));

const Item: FC = () => {
  const [selectedColor, setSelectedColor] = useState<string>('')
  const [selectedSize, setSelectedSize] = useState<string>('')
  const [detailsVisible, setDetailsVisible] = useState(false)
  const { id } = useParams()
  const navigate = useNavigate()
  const { products } = useContext(ProductContext)
  const { items, pushItem } = useContext(CartContext)
  const suggestedProductsStep = useMemo(() => 3, [])
  const [suggestedProductsPage, setSuggestedProductsPage] = useState(0)
  const { height } = useWindowDimensions()
  const location = useLocation()
  const [sizesDarwerOpen, setSizesDrawerOpen] = useState(false);
  useScrollToTop(location)
  const product = useMemo(() =>
    products?.find((pr) => pr.id === id)
  , [id, products])
  const modsForColor = useMemo(() =>
    product?.product_modifications?.filter((pm) => pm.additional_fields['color'] === selectedColor)
  , [selectedColor])
  const selectedModification = useMemo(() => 
    product?.product_modifications?.filter((pm) => 
      pm.additional_fields['color'] === selectedColor && pm.additional_fields['size'] === selectedSize
    )[0]
  , [product, selectedColor, selectedSize])
  const suggestedProducts = useMemo(() =>
    product 
      ? products?.filter((pr) => pr.additional_fields["n_collection"] === product.additional_fields["n_collection"] && pr.id != product.id)
      : []
  , [products, product])
  const suggestedProductsToDisplay = useMemo(() =>
    suggestedProducts.slice(suggestedProductsPage * suggestedProductsStep, suggestedProductsPage * suggestedProductsStep + suggestedProductsStep)
  , [suggestedProducts, suggestedProductsPage, suggestedProductsStep])
  const alreadyInTheCart = useMemo(() =>
    !!selectedModification && !!items.find((el) => el.productModificationId === selectedModification.id)
  , [items, selectedModification])

  const [successToastOpen, setSuccessToastOpen] = useState(false)

  const addToCart = () => {
    if (!selectedModification || alreadyInTheCart)
      return;
    
    pushItem(selectedModification.id)
    setSuccessToastOpen(true)
  }

  useEffect(() => {
    if (product?.available_colors)
      setSelectedColor(product?.available_colors[0])
  }, [product])

  useEffect(() => {
    if (!modsForColor) return;
    
    setSelectedSize(modsForColor[0]?.additional_fields["size"])
  }, [modsForColor])

  const onColorChange = (_: any, value: string) => setSelectedColor(value)

  const sizeType = useMemo(() => {
    if (product?.product_modifications?.some((pm) => pm.additional_fields.size?.includes('one')))
      return 'one-size'
    if (product?.product_modifications?.some((pm) => pm.additional_fields.size?.includes('-')))
      return 'range'
    return 'fixed'
  }, [product])

  if(!product)
    return null;

  return (
    <MainScreen>
      <SizesDrawer open={sizesDarwerOpen} setOpen={setSizesDrawerOpen} sizeType={sizeType} />
      <Snackbar open={successToastOpen} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={() => setSuccessToastOpen(false)}>
        <Alert severity="success" color="success">
          Товар успішно додано до корзини
        </Alert>
      </Snackbar>
      <ImageBlock>
        {product.additional_fields && product.additional_fields["images"]?.map((productImage: any) =>
          <img src={productImage["url"]} key={productImage["url"]} />
        )}
      </ImageBlock>
      <TextBlock>
        <TextBlockInner style={{ position: ((height < 800 && !!suggestedProducts.length) || height < 600) && !detailsVisible ? 'static' : undefined }}>
          <Heading>
            <Typography variant='h4' color="black">{[product.additional_fields["n_collection"], product.title].filter((el) => !!el).join(', ')}</Typography>
          </Heading>
          <br />
          <br />
          <Typography variant="body1" color="black" fontWeight={600}>Оберіть колір</Typography>
          <RadioGroup row name="color" value={selectedColor} sx={{ margin: '3px 0 0 -5px' }} onChange={onColorChange}>
            {product.available_colors.map((color) => 
              <StyledRadio 
                key={color}
                value={color}
                checkedIcon={<BpCheckedIcon color={PRODUCT_COLOR[color]} />}
                icon={<BpIcon color={PRODUCT_COLOR[color]} />}
                disableRipple
                size="small"
              />
            )}
          </RadioGroup>
          <br />
          <CustomDropdown 
            options={modsForColor?.map((pm) => pm?.additional_fields["size"]) as string[]}
            selectedValue={selectedSize}
            setSelectedValue={setSelectedSize}
          />
          <Link 
            href="#"
            onClick={(e): void => {
              e.preventDefault()
              setSizesDrawerOpen(true)
            }}
          >
            дивитися розмірну сітку
          </Link>
          <br />
          {product.additional_fields["website_details"] &&
            <ToogleLabel onClick={(): void => setDetailsVisible(!detailsVisible)}>
              <Typography variant="body1" color="black" fontWeight={600}>Деталі та опис</Typography>
              <div style={{ width: '10px' }} />
              {
                detailsVisible
                  ? <KeyboardArrowUp sx={{ fontSize: '20px' }} />
                  : <KeyboardArrowDown sx={{ fontSize: '20px' }}  />
              }
            </ToogleLabel>
          }
          {detailsVisible
            ? <ToogleBlock>
                {product.additional_fields["website_details"]}
              </ToogleBlock>
            : null
          }
          <br />
          <InStockContainer>
            <InStockBadge value={!!selectedModification?.in_stock} />
          </InStockContainer>
          {alreadyInTheCart
            ? <Button 
                variant='outlined' 
                startIcon={<Check />} 
                sx={{ alignSelf: 'flex-start', minWidth: '300px' }}
                onClick={() => navigate('/cart')}
              >
                <ButtonInner>
                  <Typography variant="h3" color="black" fontWeight={600}>уже в корзині</Typography>
                  <Typography variant="h3" color="black" fontWeight={600} sx={{ marginLeft: '15px' }}>₴{selectedModification?.price}</Typography>
                </ButtonInner>
              </Button>
            : <Button 
                variant='contained' 
                startIcon={<Add />} 
                sx={{ alignSelf: 'flex-start', minWidth: '300px' }}
                onClick={addToCart}
              >
                <ButtonInner>
                  <Typography variant="h3" color="white" fontWeight={600}>в корзину</Typography>
                  <Prices>
                    {
                      selectedModification?.oldPrice &&
                        <Typography variant="h3" color="white" fontWeight={400} sx={{ textDecoration: 'line-through', marginLeft: '10px' }}>₴{selectedModification?.oldPrice}</Typography>
                    }
                    <Typography variant="h3" color="white" fontWeight={600} sx={{ marginLeft: '10px' }}>₴{selectedModification?.price}</Typography>
                  </Prices>
                </ButtonInner>
              </Button>
          }
          <br />
          <br />
          {!!suggestedProducts.length &&
            <SuggestedProducts>
              <SuggestedProductsHeading>
                <Typography variant="body1" color="black" fontWeight={600}>Суміжні товари</Typography>
                <SuggestedProductsControls>
                  {
                    suggestedProductsPage > 0
                      ? <ArrowSvg 
                          onClick={() => setSuggestedProductsPage(suggestedProductsPage - 1)} 
                          style={{ transform: "rotate(180deg)", cursor: "pointer" }}
                        />
                      : null
                  }
                  {
                    suggestedProductsPage + 1 < Math.ceil(suggestedProducts.length / suggestedProductsStep)
                      ? <ArrowSvg 
                          onClick={() => setSuggestedProductsPage(suggestedProductsPage + 1)}
                          style={{ cursor: "pointer" }}
                        />
                      : null
                  }
                  
                </SuggestedProductsControls>
              </SuggestedProductsHeading>
              <SuggestedProductsCards>
                {
                  suggestedProductsToDisplay?.map((pr) => 
                    <ProductItem product={pr} small={true} key={pr.id}/>
                  )
                }
              </SuggestedProductsCards>
            </SuggestedProducts>
          }
        </TextBlockInner>
      </TextBlock>
    </MainScreen>
  );
};

export default Item;
