import { apiCallWrapper } from './apiCallWrapper';

export type ProductModification = {
  additional_fields: any, 
  id: string, 
  parsed_title: string,
  price: string,
  product_id: string,
  in_stock: boolean,
  oldPrice?: string;
}

export type Product = {
  id: string;
  title: string;
  additional_fields: any;
  product_modifications: ProductModification[],
  available_colors: string[],
}

export const PRODUCT_COLOR: any = {
  ["Жовтий"]: '#FFCF43',
  ["Блакитний"]: '#75ACFF',
  ["Холодний бежевий"]: '#E8DBD2',
  ["Бежевий"]: '#F5E7DE',
  ["Чорний"]: '#000',
  ["Фісташковий"]: '#BCD1C2',
  ["Білий"]: '#fff',
  ["Рожевий"]: '#FFC1E0',
  ["Червоний"]: 'red',
  ["молочний"]: '#FDFCFB',
  ["Оливковий"]: "#9CA064",
  ["Рожево-сірий"]: "#DEAFC3",
}

const prepareProduct = (product: any): Product => ({
  ...product,
  available_colors: [...new Set<string>(product.product_modifications.map((pm: any) => pm.additional_fields["color"]||''))]
})

export const getAll = async () => {
  const res = await apiCallWrapper('/products', { method: 'GET' })
  if(res.error)
    throw Error(res.error)

  return res.map((product: any) => prepareProduct(product))
}

export const getOne = async (id: string) => {
  const res = await apiCallWrapper(`/products/${id}`, { method: 'GET' })
  if(res.error)
    throw Error(res.error)

  return prepareProduct(res)
}
