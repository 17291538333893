const DO_FUNCTION_URL = 'https://faas-fra1-afec6ce7.doserverless.co/api/v1/web/fn-574dc93d-3a65-4b75-9f5e-4b41272d1b04/default/nettie-fw'

export const apiCallWrapper = (path: string, data: any) => (
  fetch(`${DO_FUNCTION_URL}${path}`, data)
    .then((res) => res.json())
    .then((res) => { if(res.error) throw Error(res.error); return res; })
)



  // export const apiCallWrapper = async (path: string, data: any) => {
  //     const response = await fetch(`http://localhost:5000/api/v1${path}`, {
  //     method: data["method"],
  //     headers: {
  //       "Content-Type": "application/json",
  //       "Authorization": `Bearer 75464badae445278537eae1ffa8c6457`
  //     },
  //     ...(data["body"] ? { body: data["body"] } : {})
  //   });

  //   return await response.json();
  // }