import { FC, useContext, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Alert, Button, Checkbox, CircularProgress, Divider, FormControlLabel, Link, Radio, RadioGroup, Snackbar, TextField, Typography } from '@mui/material';
import { ReactComponent as ArrowRightSvg } from '../assets/arrowRightWhite.svg';
import { useNavigate } from 'react-router-dom';
import { CartContext } from '../contexts/CartContext';
import { create } from '../api/Order';
import { MessagePage } from '../components/MessagePage';
import { HEADER_HEIGHT } from '../utils';
import { CommonContext } from '../contexts/CommonContext';

const MainScreen = styled('form')(() => ({
  maxWidth: '100%',
  overflowX: 'hidden',
  paddingTop: '100px',
  maxHeight: `calc(100vh - ${HEADER_HEIGHT})`,
  paddingBottom: '100px',
}));

const Title = styled("div")(() => ({
  position: 'absolute',
  justifyContent: "space-arround",
  display: "flex",
  left: '50%',
  top: '0',
  paddingTop: '12px',
  transform: 'translate(-50%,0)',
}));

const Container = styled('div')(() => ({
  display: 'flex',
  padding: '0 20px 20px',
  width: "100%",
  maxWidth: "1700px",
  margin: '0 auto',
}));

const SubmitLine = styled('div')(() => ({
  display: 'flex',
  padding: '0 20px 20px',
  width: "100%",
  maxWidth: "1700px",
  margin: '0 auto',
  justifyContent: 'space-between',
  alignItems: 'flex-start',

  '@media (max-width: 1099px)': {
    flexDirection: 'column'
  },
}));

const SubmitActions = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  paddingTop: '30px',

  '@media (max-width: 1099px)': {
    alignSelf: 'flex-end'
  },

  '@media (max-width: 767px)': {
    flexDirection: 'column',
  },

  '&>button': {
    marginLeft: '29px',

    '@media (max-width: 767px)': {
      marginLeft: '0',
      marginTop: '30px',
      flexDirection: 'column',
    },
  }
}));

const FieldsContainer = styled('div')(() => ({
  display: 'flex',
  maxWidth: '1000px',
  width: '100%',
  flexWrap: 'wrap'
}));

const FieldsColumn = styled('div')(() => ({
  flex: '1 1 auto',
  width: 'calc(50% - 140px)',

  '@media (max-width: 1099px)': {
    width: '100%',
  },

  '& + &': {
    marginLeft: '140px',

    '@media (max-width: 1099px)': {
      marginLeft: '0',
      marginTop: '20px',
    },
  }
}));

const FullWidthBlock = styled('div')(() => ({
  flex: '1 1 100%',
  width: '100%',
  maxWidth: '100%',
  minWidth: '100%'
}));


const CartConfirm: FC = () => {
  const navigate = useNavigate()
  const { items, promo, clearCart } = useContext(CartContext)
  const [submitting, setSubmitting] = useState(false)
  const [orderId, setOrderId] = useState('')
  const [contactRequired, setContactRequired] = useState(true)
  const [ibanPaymentMethod, setIbanPaymentMethod] = useState(true)
  const [isCourierDelivery, setIsCourierDelivery] = useState(false)
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const { setTitle } = useContext(CommonContext)

  useEffect(() => {
    setTitle(
      <Title>
        <Typography variant='h4' color="black">Оформлення замовлення</Typography>
      </Title>
    )

    return () => {
      setTitle(null)
    }
  }, [])

  const submitOrder = (e: any) => {
    e.preventDefault()
    const details = {
      delivery_operator: "Нова Пошта",
      delivery_address:
        `${isCourierDelivery ? 'курʼєр' : 'відділення'}: ${e.target.city_and_region?.value}, ${e.target.new_post_station?.value}`,
      delivery_recipient_name: e.target.name?.value,
      delivery_recipient_phone: e.target.phone_number?.value,
    }

    setSubmitting(true)
    create(details, items, promo)
      .then((res: { id: string }) => {
        if (res?.id) {
          setOrderId(res?.id);
        } else {
          setOpenSnackBar(true)
        }
        setSubmitting(false);
        clearCart()
      })
  }

  if (orderId)
    return (
      <MessagePage title="Дякуємо за замовлення">
        <Typography>Замовлення номер <Typography variant="caption">{orderId}</Typography> успішно оформлено!</Typography>
        {
          contactRequired || ibanPaymentMethod
            ? <Typography>Наш менеджер звʼяжеться з вами найближчим часом для уточнення деталей.</Typography>
            : <>
                <Typography>Ми не будемо турбувати вас лишній раз і просто займемось виконанням даного замовлення.</Typography>
                <Typography>Очікуйте повідомлення від поштового оператора стосовно відправки.</Typography>
              </>
        }
        <Typography>Приємного Вам настрою :)</Typography>
        <br />
        <Typography variant="caption">
          Якщо ви хочете звʼязатись з нами - пишіть нам в інстаграм{" "}
          <Link href="https://instagram.com/nettie_ua" target="_blank">@nettie_ua</Link>
        </Typography>
      </MessagePage>
    )

  return (
    <>
      <Snackbar open={openSnackBar} autoHideDuration={10000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={() => setOpenSnackBar(false)}>
        <Alert severity="error" color="error">
          Щось пішло не так і ваше замовлення не було оформлено :(
          Спробуйте, будь ласка, пізніше.
        </Alert>
      </Snackbar>
      <MainScreen onSubmit={submitOrder}>
        <Container>
          <FieldsContainer>
            <FieldsColumn>
              <Typography variant="body1" color="black" fontWeight={600}>Спосіб доставки</Typography>
              <br />
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="to_station"
                name="delivery_option"
                onChange={(_, value) => { setIsCourierDelivery(value === 'to_home') }}
              >
                <FormControlLabel value="to_station" control={<Radio />} label="доставка Новою Поштою у відділення" />
                <FormControlLabel value="to_home" control={<Radio />} label="кур’єрська доставка Новою Поштою" />
              </RadioGroup>
            </FieldsColumn>
            <FieldsColumn>
              <Typography variant="body1" color="black" fontWeight={600}>Спосіб оплати</Typography>
              <br />
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="iban"
                name="payment_option"
                onChange={(_, value) => { setIbanPaymentMethod(value === 'iban') }}
              >
                <FormControlLabel value="online" disabled control={<Radio />} label="онлайн на сайті (картка, Google Pay, Apple Pay ...)" />
                <FormControlLabel value="iban" control={<Radio />} label="оплата по IBAN (менеджер звʼяжеться з вами)" />
              </RadioGroup>
            </FieldsColumn>
            <FullWidthBlock>
              <br />
              <br />
              <Typography variant="body1" color="black" fontWeight={600}>Введіть дані</Typography>
              <br />
            </FullWidthBlock>
            <FieldsColumn>
              <TextField variant="standard" name="name" placeholder='прізвище імʼя та по батькові' fullWidth required />
              <TextField variant="standard" name="phone_number" placeholder='номер телефону' fullWidth required />
            </FieldsColumn>
            <FieldsColumn>
              <TextField variant="standard" name="city_and_region" placeholder='місто та область' fullWidth required />
              {
                isCourierDelivery
                  ? <TextField variant="standard" name="address" placeholder='адреса для курʼєрської доставки' fullWidth required />
                  : <TextField variant="standard" name="new_post_station" placeholder='відділення Нової Пошти' fullWidth required />
              }
            </FieldsColumn>
            <FullWidthBlock>
              <br />
            </FullWidthBlock>
          </FieldsContainer>
        </Container>
        <Divider />
        <SubmitLine>
          <FormControlLabel
            control={<Checkbox checked={contactRequired || ibanPaymentMethod} defaultChecked={ibanPaymentMethod} disabled={ibanPaymentMethod} onChange={(_, checked) => setContactRequired(checked)} />}
            label={<Typography variant='body2' color="#8A8A8A">зателефонувати мені для уточнення деталей</Typography>}
          />
          <SubmitActions>
            <Link
              href="#"
              onClick={(e): void => {
                e.preventDefault()
                navigate('/cart')
              }}
            >
              Повернутись назад
            </Link>
            <Button
              variant='contained'
              type="submit"
              endIcon={submitting ? <CircularProgress size={20} /> : <ArrowRightSvg style={{color: "white"}} />}
              sx={{ minWidth: '300px' }}
              disabled={submitting}
            >
              Підтвердити замовлення
            </Button>
          </SubmitActions>
        </SubmitLine>
      </MainScreen>
    </>
  );
};

export default CartConfirm;
