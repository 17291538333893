import { createContext, useEffect, useState, FC, ReactNode, useMemo, useCallback } from 'react';
import { loadImageAsync } from '../utils';
import { useLocation } from 'react-router-dom';
import { Promotion, getAll } from '../api/Promotion';
import { Product } from '../api/Product';

type CommonContextValues = {
  title: ReactNode,
  setTitle: (titleLine: ReactNode) => void,
}

export const CommonContext = createContext<CommonContextValues>({
  title: null,
  setTitle: () => {},
});

export const CommonContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [title, setTitle] = useState<ReactNode>(null);

  return (
    <CommonContext.Provider
      value={{
        title,
        setTitle,
      }}
    >
      {children}
    </CommonContext.Provider>
  );
};
