import { FC, useContext, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { ProductItem } from '../components/ProductItem';
import { ProductContext } from '../contexts/ProductContext';
import { FilterDrawer } from '../components/FilterDrawer';
import { HEADER_HEIGHT } from '../utils';
import { CommonContext } from '../contexts/CommonContext';

const MainScreen = styled('div')(() => ({
  maxWidth: '100%',
  overflowX: 'hidden',
  paddingTop: '100px',
  maxHeight: `calc(100vh - ${HEADER_HEIGHT})`,
  paddingBottom: '100px',
}));

const ListOuter = styled('div')(() => ({
  display: 'grid',
  padding: '0 20px 20px',
  width: "100%",
  maxWidth: "1700px",
  margin: '0 auto',
  gap: '30px',
  gridTemplateColumns: 'repeat(auto-fill, minmax(min(340px, 100%), 1fr))',
  justifyItems: 'center',
}));

const Title = styled("div")(() => ({
  position: 'absolute',
  justifyContent: "space-arround",
  display: "flex",
  left: '50%',
  top: '0',
  paddingTop: '12px',
  transform: 'translate(-50%,0)',
}));

const Catalogue: FC = () => {
  const { filteredProducts } = useContext(ProductContext)
  const [filterOpened, setFilterOpened] = useState(false)
  const { setTitle } = useContext(CommonContext)

  useEffect(() => {
    setTitle(
      <>
        <Typography variant='body1' color="black">товарів {filteredProducts?.length}</Typography>
        <Title>
          <Typography variant='h4' color="black">Каталог</Typography>
        </Title>
        <Typography variant='body1' color="black" onClick={() => setFilterOpened(true)} style={{ cursor: 'pointer' }}><b>фільтр</b></Typography>
      </>
    )

    return () => {
      setTitle(null)
    }
  }, [])

  return (
    <>
      <FilterDrawer open={filterOpened} setOpen={setFilterOpened} />
      <MainScreen>
        <ListOuter id="list-grid">
          {filteredProducts.map((product) => <ProductItem key={product.id} product={product} />)}
        </ListOuter>
      </MainScreen>
    </>
  );
};

export default Catalogue;
