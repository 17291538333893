import { CartItem } from '../contexts/CartContext';
import { apiCallWrapper } from './apiCallWrapper';

type OrderDetails = {
  delivery_operator: string,
  delivery_address: string,
  delivery_recipient_name: string,
  delivery_recipient_phone: string,
}

export const create = (details: OrderDetails, items: CartItem[], promo?: string) => {
  return apiCallWrapper(`/orders`, {
    method: 'POST',
    body: JSON.stringify({
      order: {
        source: 'nettie.com.ua',
        ...details,
        ...(promo ? { additional_fields: { cert_code: promo } } : {}),
        order_items_attributes: items.map((item) => ({
          count: item.count,
          product_modification_id: item.productModificationId
        }))
      }
    })
  })
}
