import './App.css';
import { ThemeProvider } from './theme/ThemeProvider';
import { CssBaseline } from '@mui/material';
import Root from './pages/Root';
import { ProductContextProvider } from './contexts/ProductContext';
import { CartContextProvider } from './contexts/CartContext';
import { FC } from 'react';
import { PromotionContextProvider } from './contexts/PromotionContext';
import { CommonContextProvider } from './contexts/CommonContext';

const App: FC = () => {
  return (
    <ThemeProvider>
      <CssBaseline />
      <CommonContextProvider>
        <PromotionContextProvider>
          <ProductContextProvider>
            <CartContextProvider>
              <Root />
            </CartContextProvider>
          </ProductContextProvider>
        </PromotionContextProvider>
      </CommonContextProvider>
    </ThemeProvider>
  );
}

export default App;
