import { FC, useContext, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Alert, Button, Divider, IconButton, Link, Snackbar, TextField, Typography } from '@mui/material';
import { CartContext } from '../contexts/CartContext';
import { CustomDropdown } from '../components/CustomDropdown';
import { Add, Close, Remove } from '@mui/icons-material';
import { ReactComponent as ArrowRightSvg } from '../assets/arrowRightWhite.svg';
import { useNavigate } from 'react-router-dom';
import { MessagePage } from '../components/MessagePage';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { HEADER_HEIGHT, LAPTOP_BREAKPOINT, TABLET_BREAKPOINT } from '../utils';
import { CommonContext } from '../contexts/CommonContext';

const MainScreen = styled('div')(() => ({
  maxWidth: '100%',
  overflowX: 'hidden',
  paddingTop: '100px',
  maxHeight: `calc(100vh - ${HEADER_HEIGHT})`,
  paddingBottom: '100px',
}));

const Title = styled("div")(() => ({
  position: 'absolute',
  justifyContent: "space-arround",
  display: "flex",
  left: '50%',
  top: '0',
  paddingTop: '12px',
  transform: 'translate(-50%,0)',
}));

const Container = styled('div')(() => ({
  display: 'flex',
  padding: '0 20px 20px',
  width: "100%",
  maxWidth: "1700px",
  margin: '0 auto',

  '@media (max-width: 767px)': {
    flexDirection: 'column'
  }
}));

const SubmitLine = styled('div')(() => ({
  display: 'flex',
  padding: '0 20px 20px',
  width: "100%",
  maxWidth: "1700px",
  margin: '0 auto',
  justifyContent: 'flex-end'
}));

const CartItemsWrapper = styled('div')(() => ({
  flex: '1 1 auto'
}));

const CartItemBlock = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 3fr 1fr 2fr 1fr 1fr 1fr',
  alignItems: 'center',
  justifyContent: 'space-between',
  gridGap: '30px',

  '@media (max-width: 1399px)': {
    gridTemplateColumns: '1fr 3fr 1fr 1fr 1fr',

    'button': {
      padding: '5px',
      marginTop: '5px'
    }
  },

  '@media (max-width: 1099px)': {
    gridTemplateColumns: '1fr 3fr',
  },

  '@media (max-width: 767px)': {
    marginBottom: '30px',
    gridGap: '20px',
  },

  "& + &": {
    marginTop: '21px'
  }
}));

const CartItemImage = styled('div')(({ theme, backgroundImage }) => ({
  height: '180px',
  width: '140px',
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
}));

const CartItemName = styled('div')(() => ({
  '@media (max-width: 1099px)': {
    paddingRight: '30px'
  },
}));

const CartItemCount = styled('div')(() => ({
  display: 'flex',
  minWidth: '100px',
  justifyContent: 'space-between',
  alignItems: 'center',

  '@media (max-width: 1399px)': {
    padding: '5px 0',
    'button': {
      marginTop: 0,
    }
  },

  '@media (max-width: 1099px)': {
    width: '100px',
  },
}));

const OrderSummaryWrapper = styled('div')(() => ({
  flex: '1 1 auto',
  minWidth: '500px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: "#F5F5F5",
  borderLeft: '1px solid #000',
  padding: '80px',

  '@media (max-width: 1099px)': {
    padding: '30px',
    minWidth: '300px',
  },
}));

const OrderSummaryLine = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

const OrderSummaryPromo = styled('div')(() => ({
  display: 'flex',
  background: '#fff',
  borderRadius: '5px',
  alignItems: 'center'
}));

const EmptyState = styled('div')(() => ({
  fontSize: '70px',
  textAlign: 'center',
  textTransform: 'uppercase',
  opacity: .1,
  fontWeight: 600,
  marginBottom: '30px'
}));

const PriceLine = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end'
}));

const Cart: FC = () => {
  const { 
    itemsWithAssociatedProductsAndModification, 
    totalSum, 
    discount, 
    setPromo, 
    changeItem, 
    changeCount, 
    popItem 
  } = useContext(CartContext)
  const navigate = useNavigate()
  const [showPromoAlert, setShowPromoAlert] = useState(false)
  const { width } = useWindowDimensions()
  const { setTitle } = useContext(CommonContext)

  useEffect(() => {
    setTitle(
      <Title>
        <Typography variant='h4' color="black">Корзина</Typography>
      </Title>
    )

    return () => {
      setTitle(null)
    }
  }, [])

  const triggerPromoAlert = () => {
    setShowPromoAlert(true)
    setTimeout(() => setShowPromoAlert(false), 6000)
  }

  if(!itemsWithAssociatedProductsAndModification.length)
    return (
      <MessagePage title="Корзина" buttonText="Почати покупки">
        <EmptyState>Тут поки пусто</EmptyState>
      </MessagePage>
    )

  return (
    <>
      <Snackbar open={showPromoAlert} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="error" color="error">
          Ви ввели код який не існує або який вже був активованим!
        </Alert>
      </Snackbar>
      <MainScreen>
        <Container>
          <CartItemsWrapper>
            {itemsWithAssociatedProductsAndModification.map((item) => {
              if (!item?.associatedProductModification || !item?.associatedProduct)
                return null;

              return (
                <CartItemBlock key={item.productModificationId}>
                  <CartItemImage backgroundImage={item.associatedProduct?.additional_fields?.preview_images && item.associatedProduct?.additional_fields?.preview_images[0]?.url} />
                  <CartItemName>
                    <Typography variant='h4'>{[item.associatedProduct.additional_fields["n_collection"], item.associatedProduct.title].filter((el) => !!el).join(', ')}</Typography>
                    <Typography variant='body2' color="#DADADA">{item.associatedProduct?.id}</Typography>
                    {
                      width < LAPTOP_BREAKPOINT
                      ? <>
                          <Typography variant='body2' color="#DADADA">{item.associatedProductModification?.additional_fields?.color}</Typography>
                          <CustomDropdown 
                            labelPresent={false}
                            options={item.associatedProduct?.product_modifications.filter((pm) => pm.additional_fields["color"] === item.associatedProductModification?.additional_fields?.color).map((pm) => pm?.additional_fields["size"]) as string[]}
                            selectedValue={item.associatedProductModification?.additional_fields?.size}
                            setSelectedValue={(v) => {
                              const newProductModId = item.associatedProduct?.product_modifications?.find((pm) => pm.additional_fields["color"] === item.associatedProductModification?.additional_fields?.color && pm?.additional_fields["size"] === v)?.id
                              if (newProductModId)
                                changeItem(newProductModId, item?.associatedProductModification?.id)
                            }}
                          />
                          {
                            width < TABLET_BREAKPOINT
                              ? <>
                                  <CartItemCount>
                                    <IconButton 
                                      onClick={(): void => changeCount(item?.associatedProductModification?.id, 1) } 
                                      sx={{color: '#414141'}}
                                      size='small'
                                    >
                                      <Add style={{ fontSize: '1rem' }} />
                                    </IconButton>
                                    <Typography variant='body1' fontWeight={600}>{item.count}</Typography>
                                    <IconButton 
                                      onClick={(): void => changeCount(item?.associatedProductModification?.id, -1)} 
                                      disabled={item.count === 1}
                                      sx={{color: '#414141'}}
                                      size='small'
                                    >
                                      <Remove style={{ fontSize: '1rem' }} />
                                    </IconButton>
                                  </CartItemCount>
                                  <PriceLine>
                                    {item?.associatedProductModification?.oldPrice &&
                                      <Typography variant='body1' fontWeight={400} align='left' color="#8A8A8A" style={{ textDecoration:"line-through", marginRight: '10px' }}>
                                        {parseFloat(item?.associatedProductModification?.oldPrice)*item.count}₴
                                      </Typography>
                                    }
                                    <Typography variant='body1' fontWeight={600} align='left'>
                                      {parseFloat(item?.associatedProductModification?.price)*item.count}₴
                                    </Typography>
                                  </PriceLine>
                                  <Link 
                                    href="#"
                                    onClick={(e): void => {
                                      e.preventDefault()
                                      popItem(item?.associatedProductModification?.id) 
                                    }}
                                  >
                                    видалити
                                  </Link>
                                </>
                              : null
                          }
                        </>
                      : null
                    }
                  </CartItemName>
                  {width >= LAPTOP_BREAKPOINT 
                    ? <>
                        <Typography variant='body1' textTransform="lowercase" align='center'>{item.associatedProductModification?.additional_fields?.color}</Typography>
                        <CustomDropdown 
                          labelPresent={false}
                          options={item.associatedProduct?.product_modifications.filter((pm) => pm.additional_fields["color"] === item.associatedProductModification?.additional_fields?.color).map((pm) => pm?.additional_fields["size"]) as string[]}
                          selectedValue={item.associatedProductModification?.additional_fields?.size}
                          setSelectedValue={(v) => {
                            const newProductModId = item.associatedProduct?.product_modifications?.find((pm) => pm.additional_fields["color"] === item.associatedProductModification?.additional_fields?.color && pm?.additional_fields["size"] === v)?.id
                            if (newProductModId)
                              changeItem(newProductModId, item?.associatedProductModification?.id)
                          }}
                        />
                      </>
                    : null
                  }
                  {
                    width >= TABLET_BREAKPOINT
                      ? <>
                          <CartItemCount>
                            <IconButton 
                              onClick={(): void => changeCount(item?.associatedProductModification?.id, 1) } 
                              sx={{color: '#414141'}}
                              size='small'
                            >
                              <Add style={{ fontSize: '1rem' }} />
                            </IconButton>
                            <Typography variant='body1' fontWeight={600}>{item.count}</Typography>
                            <IconButton 
                              onClick={(): void => changeCount(item?.associatedProductModification?.id, -1)} 
                              disabled={item.count === 1}
                              sx={{color: '#414141'}}
                              size='small'
                            >
                              <Remove style={{ fontSize: '1rem' }} />
                            </IconButton>
                          </CartItemCount>
                          <PriceLine>
                            {item?.associatedProductModification?.oldPrice &&
                              <Typography variant='body1' fontWeight={400} align='right' color="#8A8A8A" style={{ textDecoration:"line-through", marginRight: '10px' }}>
                                {parseFloat(item?.associatedProductModification?.oldPrice)*item.count}₴
                              </Typography>
                            }
                            <Typography variant='body1' fontWeight={600} align='right'>
                              {parseFloat(item?.associatedProductModification?.price)*item.count}₴
                            </Typography>
                          </PriceLine>
                          <div>
                          <IconButton 
                            onClick={(): void => popItem(item?.associatedProductModification?.id) } 
                            sx={{color: '#414141'}}
                            size='small'
                          >
                            <Close style={{ fontSize: '1rem' }} />
                          </IconButton>
                          </div>
                        </>
                      : null
                  }
                </CartItemBlock>
              )
            })}
          </CartItemsWrapper>
          <OrderSummaryWrapper>
            <OrderSummaryLine>
              <Typography lineHeight={3}>загальна вартість</Typography>
              <Typography lineHeight={3}>{totalSum}₴</Typography>
            </OrderSummaryLine>
            <OrderSummaryPromo>
              <TextField variant="outlined" autoComplete='false' placeholder='промокод' fullWidth={true} />
              <Button 
                variant='contained' 
                size="small" 
                sx={{ minWidth: '162px', margin: '0 -3px' }} 
                onClick={() => triggerPromoAlert()}
                disabled={showPromoAlert}
              >
                застосувати
              </Button>
            </OrderSummaryPromo>
            <OrderSummaryLine>
              <Typography lineHeight={3}>знижка</Typography>
              <Typography lineHeight={3}>{discount}₴</Typography>
            </OrderSummaryLine>
            <br />
            <Divider />
            <OrderSummaryLine>
              <Typography fontWeight={600} lineHeight={3}>Сума до оплати</Typography>
              <Typography fontWeight={600} lineHeight={3}>{totalSum - discount}₴</Typography>
            </OrderSummaryLine>
          </OrderSummaryWrapper>
          
        </Container>
        <SubmitLine>
          <Button 
            variant='contained' 
            endIcon={<ArrowRightSvg style={{color: "white"}} />} 
            sx={{ minWidth: '300px' }}
            onClick={(): void => navigate('/cart/confirm')}
          >
            перейти до оформлення
          </Button>
        </SubmitLine>
      </MainScreen>
    </>
  );
};

export default Cart;
