import { createContext, useState, FC, ReactNode, useEffect, useMemo, useCallback, useContext } from 'react';
import { Product, ProductModification } from '../api/Product';
import { ProductContext } from './ProductContext';

export type CartItem = {
  productModificationId: string,
  count: number,
}

type CartContextProps = {
  items: CartItem[],
  discount: number,
  promo: string,
  totalSum: number;
  itemsWithAssociatedProductsAndModification: ItemWithAssociatedProduct[],
  clearCart(): void;
  setPromo(promo: string): void;
  pushItem(productModificationId: string): void,
  popItem(productModificationId: string): void,
  changeCount(productModificationId: string, increment: number): void,
  changeItem(newProductModificationId: string, oldProductModificationId: string): void,
}

export const CartContext = createContext<CartContextProps>({
  items: [],
  discount: 0,
  promo: '',
  totalSum: 0,
  itemsWithAssociatedProductsAndModification: [],
  clearCart: () => {},
  setPromo: () => {},
  pushItem: () => {},
  popItem: () => {},
  changeCount: () => {},
  changeItem: () => {},
});

export interface ItemWithAssociatedProduct extends CartItem {
  associatedProduct: Product;
  associatedProductModification: ProductModification
}

export const CartContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { products } = useContext(ProductContext)
  const [items, setItems] = useState<CartItem[]>(JSON.parse(localStorage.getItem('cart') || '[]'));
  const [promocodeDiscount, setPromocodeDiscount] = useState(0)
  const [promo, setPromo] = useState('')
  
  const itemsWithAssociatedProductsAndModification: ItemWithAssociatedProduct[] = useMemo(() => {
    const clonedItems = structuredClone(items)
    const newItems = clonedItems.map((item: CartItem) => {
      const associatedProduct = products.find((pr) => pr.product_modifications.find((pm) => pm.id === item.productModificationId))
      const associatedProductModification = associatedProduct?.product_modifications?.find((pm) => pm.id === item.productModificationId) 
      if(!associatedProduct || !associatedProductModification) 
        return undefined;
      return ({ ...item, associatedProduct, associatedProductModification })
    }).filter((el: any) => !!el) as ItemWithAssociatedProduct[]

    return newItems;
  }, [items, products])
  const totalSum = useMemo(() =>
    itemsWithAssociatedProductsAndModification.map((item) =>
      parseFloat(item.associatedProductModification?.oldPrice || item.associatedProductModification?.price || '0') * item.count
    ).reduce((accumulator, currentValue) => {
      return accumulator + currentValue
    }, 0)
  , [items, itemsWithAssociatedProductsAndModification])

  const discount = useMemo(() =>
    totalSum - itemsWithAssociatedProductsAndModification.map((item) =>
      parseFloat(item.associatedProductModification?.price || '0') * item.count
    ).reduce((accumulator, currentValue) => {
      return accumulator + currentValue
    }, 0) + promocodeDiscount
  , [items, itemsWithAssociatedProductsAndModification])

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(items))
  }, [items])

  const pushItem = 
    (productModificationId: string) => setItems([...items, { productModificationId, count: 1}])
  const popItem = 
    (productModificationId: string) => setItems(items.filter((item) => item.productModificationId != productModificationId))

  const changeCount = (productModificationId: string, increment: number) => {
    const newItems = structuredClone(items) as CartItem[]
    const itemToUpdate = newItems.find((item) => item.productModificationId === productModificationId)
    
    if(!itemToUpdate) return;
    
    const newCount = itemToUpdate.count + increment
    itemToUpdate.count = newCount != 0 ? newCount : itemToUpdate.count

    setItems(newItems)
  }

  const changeItem = (newProductModificationId: string, oldProductModificationId: string) => {
    const newItems = structuredClone(items) as CartItem[]
    const itemToUpdate = newItems.find((item) => item.productModificationId === oldProductModificationId)
    
    if(!itemToUpdate) return;
    
    itemToUpdate.productModificationId = newProductModificationId

    setItems(newItems)
  }

  const clearCart = () => {
    setItems([])
  }

  return (
    <CartContext.Provider
      value={{
        items,
        discount,
        promo,
        totalSum,
        itemsWithAssociatedProductsAndModification,
        clearCart,
        setPromo,
        pushItem,
        popItem,
        changeCount,
        changeItem,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
