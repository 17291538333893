import { FC } from 'react';
import { styled } from '@mui/material/styles';
import { Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as LogoSvg } from '../assets/logo.svg';
import { ReactComponent as DividerSvg } from '../assets/divider.svg';
import { ReactComponent as ArrowRightSvg } from '../assets/arrowRightWhite.svg';
import mainImagePath from '../assets/mainImage.png';
import { HEADER_HEIGHT, MOBILE_BREAKPOINT } from '../utils';
import useWindowDimensions from '../hooks/useWindowDimensions';

const MainScreen = styled('div')(() => ({
  maxWidth: '100%',
  overflowX: 'hidden',
}));

const MainScreenInner = styled('div')(() => ({
  display: 'flex',
  padding: '0 20px',
  width: "100%",
  maxWidth: "1300px",
  margin: '0 auto',
  
  '@media (max-width: 767px)': {
    flexDirection: 'column',
    padding: '0',
  },
}));

const ImageBlock = styled('div')(() => ({
  maxWidth: "calc(50% + 100px)",
  display: 'flex',
  justifyContent: 'flex-end',
  maxHeight: `calc(100vh - ${HEADER_HEIGHT})`,

  '@media (max-width: 767px)': {
    maxWidth: '100vw',
    maxHeight: 'auto',
  },

  '&>img': {
    height: '100%',

    '@media (max-width: 767px)': {
      width: '100%',
      height: 'auto',
    },
  }
}));

const TextBlock = styled('div')(() => ({
  paddingLeft: '30px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: `calc(100vh - ${HEADER_HEIGHT})`,

  '@media (max-width: 767px)': {
    height: 'auto',
    widht: '100%',
    padding: '30px'
  },
}));

export const Landing: FC = () => {
  const navigate = useNavigate()
  const { width } = useWindowDimensions()

  return (
    <MainScreen>
      <MainScreenInner>
        <ImageBlock>
          <img src={mainImagePath} />
        </ImageBlock>
        <TextBlock>
          {
            width > MOBILE_BREAKPOINT
              ? <>
                  <LogoSvg />
                  <br />
                  <br />
                  <DividerSvg />
                  <br />
                </>
              : null
          }
          <Typography variant='h1'>
            Український бренд піжамок
          </Typography>
          <Typography variant='subtitle1'>
            зроблено з любовʼю для тебе
          </Typography>
          <br />
          <br />
          <Button 
            variant='contained' 
            endIcon={<ArrowRightSvg style={{color: "white"}} />} 
            sx={{ maxWidth: '300px' }}
            onClick={(): void => navigate('/catalogue')}
          >
            Почати покупки
          </Button>
        </TextBlock>
      </MainScreenInner>
    </MainScreen>
  );
};

export default Landing;
