import { FC } from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const Container = styled(Paper)(({ theme }) => ({
  minWidth: "500px",
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  padding: '20px 40px 20px 75px',
  maxWidth: '100%',

  '@media (max-width: 767px)': {
    minWidth: "300px",
    padding: '20px 20px 20px 20px',
  },
}));

const Heading = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: 'center'
}));

const SizeItem = styled('div')(({ theme, color }) => ({
  padding: '30px 0',
  maxWidth: '385px',

  '& + &': {
    borderTop: '1px solid #DADADA'
  }
}));



const sizesData = {
  'one-size': [{
    label: '',
    headings: ['', 'груди', 'талія', 'стегна'],
    rows: [
      ['one size', '78-90', '60-76', '84-100'],
      ['one size 2', '90-106', '72-88', '96-112'],
    ]
  }],
  'range': [{
    label: 'верхні плечові вироби',
    headings: ['', 'груди', 'талія', 'стегна'],
    rows: [
      ['xs-s', '82-89', '63-70', '89-97'],
      ['s-m', '86-93', '67-74', '94-101'],
      ['m-l', '90-97', '71-78', '98-105'],
    ]
  },{
    label: 'поясні вироби',
    headings: ['', 'талія', 'стегна', ''],
    rows: [
      ['xs-s', '62-70', '86-94', ''],
      ['s-m', '66-74', '90-98', ''],
      ['m', '70-78', '94-102', ''],
    ]
  }],
  'fixed': [{
    label: '',
    headings: ['', 'груди', 'талія', 'стегна'],
    rows: [
      ['xs', '78-82', '60-64', '84-88'],
      ['s', '82-86', '64-68', '88-92'],
      ['m', '86-90', '68-72', '92-96'],
      ['l', '90-94', '72-76', '96-100'],
      ['xl', '94-98', '76-80', '100-104'],
      ['2xl', '98-102', '80-84', '104-108'],
      ['3xl', '102-106', '84-88', '108-112'],
    ]
  }],
}

type SizesDrawerProps = {
  sizeType: 'one-size' | 'range' | 'fixed';
  open: boolean;
  setOpen(open: boolean): void;
}

export const SizesDrawer: FC<SizesDrawerProps> = ({ open, setOpen, sizeType }) => {

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={(): void => setOpen(false)}
      onOpen={(): void => setOpen(true)}
    >
      <Container>
        <Heading>
          <Typography variant='body2'>розмірна сітка</Typography>
          <IconButton onClick={(): void => setOpen(false)}>
            <CloseOutlined />
          </IconButton>
        </Heading>
        <br />
        <br />
        {sizesData[sizeType].map((table) => 
          <SizeItem>
            {table.label && <Typography variant='body1' fontWeight={600} color="black" sx={{pb: '15px'}}>{table.label}</Typography>}
            <Table style={{ tableLayout: 'fixed' }}>
              <TableHead>
                {table.headings?.map((heading) => <TableCell key={heading}>{heading}</TableCell>)}
              </TableHead>
              <TableBody>
                {table.rows?.map((row) => 
                  <TableRow key={Math.random()}>
                    {row?.map((cell) => <TableCell key={cell}>{cell}</TableCell>)}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </SizeItem>
        )}
      </Container>
    </SwipeableDrawer>
  )
}