import { createContext, useEffect, useState, FC, ReactNode, useMemo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Promotion, getAll } from '../api/Promotion';
import { Product } from '../api/Product';

type PromotionContextValues = {
  loading: boolean;
  relatedPromotion: (product: Product) => Promotion | undefined;
  isActive: (promo: Promotion) => boolean;
  activeGlobalPromosion: Promotion | undefined
  promotions: Promotion[];
}

export const PromotionContext = createContext<PromotionContextValues>({
  loading: true,
  relatedPromotion: () => undefined,
  isActive: () => false,
  activeGlobalPromosion: undefined,
  promotions: [],
});

export const PromotionContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [promotions, setPromotions] = useState<Promotion[]>([]);
  const [loading, setLoading] = useState(true);
  const [lastFetcherAt, setLastFetchAt] = useState<number>(0)
  const location = useLocation()

  const isActive = (promotion: Promotion): boolean => {
    let res = false
    if(promotion.starts_at) {
      res = Date.now() > new Date(promotion.starts_at).getTime()
    }

    if(promotion.expires_at) {
      res = res && Date.now() < new Date(promotion.expires_at).getTime()
    }

    return res
  }

  const relatedPromotion = (product: Product) => {
    return promotions?.find(
      (pr) =>
        isActive(pr) &&
        (pr.affects_all ||
          product.product_modifications.some(
            (pm) => pr.promotion_items.some(
              (pi) => pi.product_modification_id === pm.id
            )
          ))
    )
  }

  const activeGlobalPromosion = useMemo(() =>
    promotions.find((pr) => isActive(pr))
  , [promotions])

  const fetchData = async () => {
    setLoading(true)
    const allPromotions: Promotion[] = await getAll()
    setPromotions(allPromotions)
    setLastFetchAt(Date.now())
    setLoading(false)
  }

  useEffect(() => {
    if (Date.now() - lastFetcherAt > 1800000)
      fetchData()
  }, [location])

  return (
    <PromotionContext.Provider
      value={{
        loading,
        relatedPromotion,
        activeGlobalPromosion,
        promotions,
        isActive
      }}
    >
      {children}
    </PromotionContext.Provider>
  );
};
