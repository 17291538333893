export const HEADER_HEIGHT = '60px';
export const MOBILE_BREAKPOINT = 768;
export const TABLET_BREAKPOINT = 1100;
export const LAPTOP_BREAKPOINT = 1400;

export const loadImageAsync = (url: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => resolve();
    image.onerror = reject;
    image.src = url;
  });
}
