import { apiCallWrapper } from './apiCallWrapper';

export type PromotionItem = {
  product_modification_id: string, 
}

export type Promotion = {
  id: string;
  title: string;
  promotion_items: PromotionItem[],
  discount: string, 
  discount_type: string, 
  affects_all: boolean,
  starts_at: string,
  expires_at: string
}

export const getAll = async () => {
  const res = await apiCallWrapper('/promotions', { method: 'GET' })
  if(res.error)
    throw Error(res.error)

  return res
}
