import { FC, useContext } from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { Badge, Checkbox, Divider, FormControlLabel, FormGroup, IconButton, Link, Paper, Typography } from '@mui/material';
import { CloseOutlined, Check } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { ProductFilter, ProductContext, baseFilters, sortingOption } from '../contexts/ProductContext';
import { PRODUCT_COLOR } from '../api/Product';
import { CustomDropdown } from './CustomDropdown';

const Container = styled(Paper)(({ theme }) => ({
  minWidth: "500px",
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  padding: '20px 40px 20px 75px',
  maxWidth: '100%',

  '@media (max-width: 767px)': {
    minWidth: "300px",
    padding: '20px 20px 20px 20px',
  },
}));

const Heading = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: 'center'
}));

const BpIcon = styled('span')(({ theme, color }) => ({
  borderRadius: '50%',
  width: 20,
  height: 20,
  boxShadow: '0 3px 7px rgba(0, 0, 0, 0.2)',
  background: color,
  '.Mui-focusVisible &': {
    outline: 'none',
  },
  'input:hover ~ &': {
    background: color,
    opacity: .8
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: color,
  },
}));

const BpCheckedIcon = styled(BpIcon)(({ theme, color }) => ({
  backgroundColor: color,
  '&:before': {
    display: 'block',
    width: 20,
    height: 20,
    backgroundImage: 'radial-gradient(#000,#000 20%,transparent 32%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: color,
  },
}));

const Items = styled("div")(({ theme }) => ({
  '.MuiBadge-standard': {
    top: '5px',
    right: '-5px'
  }
}));

const CheckboxLabel = styled("div")(({ theme }) => ({
  display: 'flex'
}));

const SizeBlock = styled("div")(({ theme }) => ({
  display: 'grid',
  gap: '10px',
  marginTop: '10px',
  gridTemplateColumns: 'repeat(auto-fill, minmax(80px, 1fr))'
}));

const SizeLabel = styled("div")(({ theme, checked }) => ({
  display: 'flex',
  border: checked ? '1px solid #6F6F6F' : '',
  borderBottom: checked ? '' : '1px solid #6F6F6F',
  padding: '5px 20px',
  width: '80px',
  height: '30px',
  alignItems: 'center',
  justifyContent: 'center'
}));


type FilterDrawerProps = {
  open: boolean;
  setOpen(open: boolean): void;
}

export const FilterDrawer: FC<FilterDrawerProps> = ({ open, setOpen }) => {
  const { filters, sortBy, setSortBy, setFilters } = useContext(ProductContext)

  const handleChange = (fieldName: string) => (e: any, checked: boolean) => {
    const newFilters = structuredClone(filters) as ProductFilter[]
    newFilters.map((el) => {
      if (el.fieldName === fieldName)
        el.values = checked ? [...el.values, e.target.name] : el.values.filter((v) => v != e.target.name)
      return el;
    })

    setFilters(newFilters)
  }

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={(): void => setOpen(false)}
      onOpen={(): void => setOpen(true)}
    >
      <Container>
        <Heading>
          <Link 
            href="#"
            onClick={(e): void => {
              e.preventDefault()
              setFilters(baseFilters)
              setSortBy(sortingOption[0])
            }}
          >
            скинути фільтри
          </Link>
          <IconButton onClick={(): void => setOpen(false)}>
            <CloseOutlined />
          </IconButton>
        </Heading>
        <br />

        <Items>
          <Badge badgeContent={filters.find((el) => el.fieldName === 'color')?.values?.length}>
            <Typography variant='body1' fontWeight={600}>колір</Typography>
          </Badge>
          <FormGroup>
            {Object.keys(PRODUCT_COLOR).map((color) => {
              const checked = filters.find((el) => el.fieldName === 'color')?.values.includes(color)
              return (
                <FormControlLabel
                  key={color}
                  control={
                    <Checkbox 
                      checkedIcon={<BpCheckedIcon color={PRODUCT_COLOR[color]} />}
                      icon={<BpIcon color={PRODUCT_COLOR[color]} />}
                      checked={checked} 
                      onChange={handleChange('color')} 
                      name={color} 
                    />
                  }
                  label={
                    <CheckboxLabel>
                      <Typography color={checked ? "#000" : undefined} textTransform="lowercase">{color}</Typography>
                      {checked && <Check style={{ fontSize: '1.2rem', marginLeft: '5px' }} />}
                    </CheckboxLabel>
                  }
                />
              )
            })}
          </FormGroup>
          <br />
          <Divider />
          <br />
          <Badge badgeContent={filters.find((el) => el.fieldName === 'size')?.values?.length}>
            <Typography variant='body1' fontWeight={600}>розмір</Typography>
          </Badge>
          <SizeBlock>
            {['xs', 's', 'm', 'l', 'xl', 'xxl'].map((size) => {
              const checked = filters.find((el) => el.fieldName === 'size')?.values.includes(size)
              return (
                <SizeLabel onClick={() => handleChange('size')({target: { name: size }}, !checked)} name={size} checked={checked} key={size}>
                  <Typography color={checked ? "#000" : undefined} textTransform="lowercase">{size}</Typography>
                </SizeLabel>
              )
            })}
          </SizeBlock>
          <br />
          <Divider />
          <br />
          <CustomDropdown
            variant='plain'
            customLabel={'сортувати за:'}
            options={sortingOption}
            selectedValue={sortBy}
            setSelectedValue={setSortBy}
          />
        </Items>
      </Container>
    </SwipeableDrawer>
  )
}